<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col
        class="mb-4"
        cols="12"
      >
        <b-link class="brand-logo">

          <color-logo />

          <h2 class="brand-text text-primary ml-1">
            {{ siteTitle }}
          </h2>
        </b-link>
      </b-col>

      <b-col
        cols="10"
        class="px-xl-2 p-1 mx-auto"
      >
        <b-card>

          <div>
            <form-wizard
              ref="formWizard"
              color="#FAA70A"
              :title="null"
              :subtitle="null"
              :active-tab-index="wizardIndex"
              shape="circle"
              hide-buttons
              class="mb-3"
            >

              <tab-content
                title="Sign Up Type"
              >
                <b-row class="d-flex justify-content-center mt-5 mb-5">
                  <b-col
                    class="d-flex justify-content-center mt-5 mb-5"
                    cols="5"
                  >
                    <b-button
                      variant="primary"
                      class="px-5 py-2"
                      @click="nextClickCompany"
                    >
                      <fa-icon
                        size="7"
                        pack="fas"
                        icon="building"
                      />
                      <h2
                        class="mt-2"
                        style="color: white"
                      >
                        {{ $t('Company') }}
                      </h2>
                    </b-button>
                  </b-col>
                  <b-col
                    class="d-flex justify-content-center mt-5 mb-5"
                    cols="5"
                  >

                    <b-button
                      variant="primary"
                      class="px-5 py-2"
                      @click="nextClickEmployee"
                    >
                      <fa-icon
                        size="7"
                        pack="fas"
                        icon="user"
                      />
                      <h2
                        class="mt-2"
                        style="color: white"
                      >
                        Employee
                      </h2>
                    </b-button>
                  </b-col>
                </b-row>
              </tab-content>

              <tab-content
                title="Personal Info"
              >
                <register-form :register-type="register_as" />
              </tab-content>
            </form-wizard>

          </div>
        </b-card>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { FormWizard, TabContent } from 'vue-form-wizard'
import store from '@/store/index'
import ColorLogo from '@/layouts/components/ColorLogo.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import RegisterForm from '@/views/auth/components/Register-form.vue'

export default {
  components: {
    RegisterForm,
    ColorLogo,
    FormWizard,
    TabContent,
  },
  data() {
    return {
      register_as: '',
      wizardIndex: 0,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    siteTitle() {
      return this.$store.getters['app/title']
    },
  },
  methods: {
    nextClickEmployee() {
      this.$refs.formWizard.nextTab()
      this.register_as = 'employee'
    },
    nextClickCompany() {
      this.register_as = 'company'
      this.$refs.formWizard.nextTab()
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
