<template>
  <div>
    <b-card-title
      v-if="register_as==='company'"
      title-tag="h2"
      align="center"
      class="font-weight-bold font-large-1 mb-1"
    >
      {{ $t('Find the perfect Candidate for your Company') }}
    </b-card-title>
    <b-card-title
      v-else
      title-tag="h2"
      align="center"
      class="font-weight-bold font-large-1 mb-1"
    >
      {{ $t('Find the perfect job for you') }}
    </b-card-title>
    <b-card-text
      align="center"
      class="mb-2 font-weight-bold font-medium-4"
    >
      {{ $t('Sign Up As') }} {{ UserData.email }}
    </b-card-text>
    <b-form class="auth-register-form mt-2">
      <b-row>
        <b-col
          v-for="(items,index) in stringInput"
          :key="index"
          cols="6"
        >
          <vx-input
            v-model="UserData[items]"
            :type="items==='password'?items:'text'"
            disable
            class="mt-2"
            :label="$t(items)"
            :errors="errors[items]"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="(items,index) in TextInput"
          :key="index"
          cols="6"
          class="mt-1"
        >
          <vs-textarea
            v-model="UserData[items]"
            :label="$t(items)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="(items,index) in BooleanInput"
          :key="index"
          cols="12"
          class="mt-2"
        >
          <b-form-checkbox
            v-model="UserData[items]"
            class="custom-control-primary"
          >
            {{ $t(`${items}_text`) }}
          </b-form-checkbox>

        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-end mt-4"
        >
          <b-button
            variant="primary"
            type="submit"
            @click.prevent="validationForm"
          >
            {{ $t('Sign up') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
/* eslint-disable */
export default {
    name: "RegisterForm",
      props:['registerType'],
    data: () => ({
      common: {
        string:['first_name','last_name','password','phone'],
        text:['address','ex_address'],
      }, company: {
        text:[],
        string:['business_name','contact_person'],
      }, employee: {
        string:['qualifications','institution_name','job_address'],
        text:['profile_summary','additional_information'],
        boolean:['refugee_status','work_permit'],
      },
      UserData: {
        //common
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        phone: '',
        address: '',
        ex_address: '',
        key: '',
        //company
        business_name: '',
        contact_person: '',
        //employee
        profile_summary: '',
        qualifications: '',
        institution_name: '',
        job_address: '',
        additional_information: '',
        work_permit: false,
        refugee_status: false,
        employment_type: 'full_time',
        //type
        register_as: '',
      },
      errors: [],
    }),
    computed: {
      stringInput(){
        if (this.register_as==='employee')
          return this.common.string.concat(this.employee.string)
        else if(this.register_as==='company')
          return this.common.string.concat(this.company.string)
      }, TextInput(){
        if (this.register_as==='employee')
          return this.common.text.concat(this.employee.text)
        else if(this.register_as==='company')
          return this.common.text.concat(this.company.text)
      } ,BooleanInput(){
        if (this.register_as==='employee')
          return this.employee.boolean
        return []
      },
      register_as: {
        get() {
          return this.registerType
        },
        set(val) {
          this.$emit('input', val)
        },
      },
    },
  created() {
    this.UserData.email = this.$route.params.email
    this.UserData.key = this.$route.params.link
  },
    methods: {
      validationForm() {
        this.UserData.register_as = this.register_as
        this.$store.dispatch('auth/SignUp', this.UserData).then(() => {
          this.$router.push({ name: 'login' })
        })
      },
    },
}
</script>

<style scoped>

</style>
