import { render, staticRenderFns } from "./Register-form.vue?vue&type=template&id=3a06c532&scoped=true&"
import script from "./Register-form.vue?vue&type=script&lang=js&"
export * from "./Register-form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a06c532",
  null
  
)

export default component.exports